import React, { useEffect } from 'react';
import { Box, Button, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import OpportunityExp from './OpportunityExp';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { useHistory } from 'react-router-dom';
import { useQb } from '../../QB/useQb';

const useStyles = makeStyles(theme => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
}));

// Event Crews
const Opportunities = {
  fids: {
    crew_age: '70',
  },
  dbid: 'QB_TBL_EVENT_CREWS',
};

function OpportunitiesList({
  userData,
  eventVolunteerRid,
  updateEV,
  isCrewTransfer,
}) {
  const history = useHistory();
  const [
    {
      data: opportunitiesData,
      loading: opportunitiesLoading,
      error: opportunitiesError,
    },
    requestOpportunities,
  ] = useQb({ dbid: Opportunities.dbid, api: 'API_DoQuery' }, { manual: true });

  useEffect(() => {
    const qbUser = userData.body.qdbapi.record;
    const ageFilter = `AND{'70'.LTE.'${qbUser.age}'}`;
    const volStillReqFilter = "AND{'26'.GT.'0'}";
    // Get Event Crews where the event is current = true, age filter, volunteers still required
    requestOpportunities({
      query: `{'73'.EX.'true'}${ageFilter}${volStillReqFilter}`,
      clist: '3.50.10.11.12.13.14.15.26.70.71.72',
    });
  }, [userData]);

  const resOpportunities =
    opportunitiesData && opportunitiesData.body.qdbapi.record;

  // Make sure the event volunteers list is an array
  const opportunities =
    resOpportunities && Array.isArray(resOpportunities)
      ? resOpportunities
      : resOpportunities
      ? [resOpportunities]
      : [];

  const classes = useStyles();
  // console.log('Opportunities List ', opportunities);
  return (
    <>
      {opportunitiesLoading && (
        <>
          <Typography
            variant="h6"
            align="center"
            color="textSecondary"
            paragraph
          >
            Loading ...
          </Typography>
        </>
      )}
      {opportunitiesError && (
        <>
          <Typography
            variant="h6"
            align="center"
            color="textSecondary"
            paragraph
          >
            {opportunitiesError}
          </Typography>
        </>
      )}
      {opportunities.length < 1 && (
        <>
          <div className={classes.heroContent}>
            <Container maxWidth="sm">
              <Typography
                variant="h6"
                align="center"
                color="textSecondary"
                paragraph
              >
                No opportunities currently available.
              </Typography>
              <Box>
                <Button
                  onClick={() => {
                    history.push('userprofile');
                  }}
                >
                  Return to Profile
                </Button>
              </Box>
            </Container>
          </div>
        </>
      )}
      {opportunities.length > 0 && (
        <>
          <div className={classes.heroContent}>
            <Container maxWidth="sm">
              <Typography
                variant="h5"
                align="center"
                color="textSecondary"
                paragraph
              >
                Please select from available opportunities.
              </Typography>

              <Grid container justify="center">
                <Grid item>
                  <Button
                    className={classes.heroButtons}
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      history.push('userprofile');
                    }}
                  >
                    Return to Profile
                  </Button>
                </Grid>
              </Grid>
            </Container>
          </div>
          <Container className={classes.cardGrid} maxWidth="md">
            {/* End hero unit */}
            <Grid container spacing={4}>
              {opportunities.map(opp => (
                <Grid item xs={12} sm={6} md={4} key={opp.record_id_}>
                  <OpportunityExp
                    key={opp.record_id_}
                    eventCrewId={opp.record_id_}
                    crewName={opp.crew_name}
                    crewDescriptionFmt={
                      opp.crew_description_formatted &&
                      opp.crew_description_formatted.VALUE
                        ? opp.crew_description_formatted.VALUE
                        : opp.crew_description_formatted
                    }
                    certBusiness={opp.crew___business_hours}
                    certDriversLicense={opp.crew___drivers_license}
                    certPoliceCheck={opp.crew___police_check}
                    certSafeFood={opp.crew___safe_food_handling_certification}
                    certProserve={opp.crew___proserve_training}
                    certAge={opp.crew___age}
                    certMassage={opp.crew___massage}
                    // selectCrew={crew => handleSelectCrew(crew)}
                    userData={userData}
                    eventVolunteerRid={eventVolunteerRid}
                    updateEV={updateEV}
                    isCrewTransfer={isCrewTransfer}
                  />
                </Grid>
              ))}
            </Grid>
          </Container>
        </>
      )}
    </>
  );
}

export default OpportunitiesList;
