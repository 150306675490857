import { combineReducers } from 'redux'
import oppReducer from './Opportunity/oppReducer'
import userReducer from './User/userReducer'
import certReducer from './Certification/certReducer'

const rootReducer = combineReducers({
    oppReducer,
    userReducer,
    certReducer
})

export default rootReducer