import React from 'react';
import { Button, Box, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

const Fail = quizSetter => {
  const history = useHistory();
  const gotoQuiz = () => {
    console.log('Return to quiz');
    quizSetter.quizSetter('quiz');
  };
  const gotoVideos = () => {
    console.log('Go to videos');
    history.push('videos');
  };
  return (
    <>
      <Typography>
        Thank you for your interest in our volunteer program. Unfortunately your
        answers were not satisfactory to pass our CFMF Volunteer Program Quiz at
        this time.
      </Typography>
      <Typography>
        You are welcome to try the quiz again by clicking the button below:
      </Typography>
      <Box m={2} pt={3}>
        <Button
          style={{ minWidth: '240px' }}
          type="button"
          variant="contained"
          color="primary"
          onClick={gotoQuiz}
        >
          Return to Quiz
        </Button>
      </Box>
      <Box m={2} pt={3}>
        <Button
          style={{ minWidth: '240px' }}
          type="button"
          variant="contained"
          color="primary"
          onClick={gotoVideos}
        >
          Videos
        </Button>
      </Box>
    </>
  );
};

export default Fail;
