import React from "react";
import { Route, Redirect } from "react-router-dom";

import { useAuth } from "../../QB/useAuth";

const PrivateRoute = ({ component: Component, ...routeProps }) => {
  const [{ currentUser }] = useAuth();
  return (
    <Route
      {...routeProps}
      render={props =>
        currentUser !== null ? <Component {...props} /> : <Redirect to="/" />
      }
    ></Route>
  );
};

export default PrivateRoute;
