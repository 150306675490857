import React from 'react';
import NavBar from '../Common/NavBar/NavBar';
import videoData from './videoData.json';
import VideoCard from './VideoCard';
import { Box, Button, Container, Grid, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../QB/useAuth';
import { useQb, fetchBy } from '../../QB/useQb';
import { User } from '../../QB/User';

function Videos() {
  const history = useHistory();
  const gotoQuiz = () => {
    history.push('quiz');
  };
  const [{ currentUser }] = useAuth();
  const [{ data: userData, loading: userLoading, error: userError }] = useQb(
    fetchBy(User, 'email', currentUser)
  );
  const showQuiz = parseInt(
    userData &&
      !userError &&
      !userLoading &&
      userData.body.qdbapi.record.show_quiz
  );
  return (
    <>
      <NavBar position="relative" />
      <Container>
        <Box m={2}>
          <Typography variant="h4" gutterBottom={true}>
            CFMF Volunteer Onboarding Videos
          </Typography>
        </Box>
        <Grid container spacing={2}>
          {videoData.map(data => {
            return <VideoCard data={data} key={data.key} />;
          })}
          {showQuiz === 1 && (
            <Grid item lg={6} xs={12}>
              <Box m={2} pt={3}>
                <Button
                  fullWidth
                  type="button"
                  variant="contained"
                  color="primary"
                  onClick={gotoQuiz}
                >
                  Take the Quiz
                </Button>
              </Box>
            </Grid>
          )}
        </Grid>
      </Container>
    </>
  );
}

export default Videos;
