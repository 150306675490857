import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const VideoCard = ({ data }) => {
  return (
    <Grid item xs={12} md={6} lg={4}>
      <Card sx={{ maxWidth: 345 }}>
        <CardMedia
          component="iframe"
          allowFullScreen="allowFullScreen"
          image={`https://www.youtube.com/embed/${data.id}`}
        />

        <CardContent>
          <Typography gutterBottom={true} variant="h6" component="div">
            {data.heading}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {data.body}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default VideoCard;
