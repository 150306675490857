import React, { useEffect } from 'react';
import axios from 'axios';
import {
  Avatar,
  Box,
  Button,
  Checkbox as MuiCheckbox,
  Container,
  CssBaseline,
  Grid,
  Link,
  Select as MuiSelect,
  TextField as MuiTextField,
  Typography,
} from '@material-ui/core';
import Copyright from '../Common/Copyright';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useHistory, Redirect } from 'react-router-dom';
import { useStyles } from '../Common/useStyles';
import { TextField } from '../Common/form/formComponents';
import LockOpenOutlinedIcon from '@material-ui/icons/LockOpenOutlined';
import {
  useAuth,
  actions as authActions,
  useSignupRequest,
} from '../../QB/useAuth';

const validationSchema = Yup.object().shape({
  first_name: Yup.string().required('First name is required'),
  last_name: Yup.string().required('Last name is required'),
  email: Yup.string()
    .email()
    .required('You must enter a valid email address.'),
  password: Yup.string()
    .required('Password is required')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character'
    ),
  confirm_password: Yup.string().when('password', {
    is: val => (val && val.length > 0 ? true : false),
    then: Yup.string().oneOf(
      [Yup.ref('password')],
      'Both password need to be the same'
    ),
  }),
});

const initialValues = {
  first_name: '',
  last_name: '',
  email: '',
  password: '',
  confirm_password: '',
};

function SignupLong() {
  const classes = useStyles();
  const [{ currentUser, authError }, authDispatch] = useAuth();
  const [{ data, loading, error }, signup] = useSignupRequest();

  // Check to see if email is already in use.

  useEffect(() => {
    data && data.status === 200
      ? authDispatch([authActions.AUTHENTICATED, data.current_user])
      : data && data.status === 401
      ? authDispatch([authActions.NOT_AUTHENTICATED, data.error])
      : data && data.status === 500
      ? authDispatch([authActions.NOT_AUTHENTICATED, data.error])
      : authDispatch([authActions.NOT_AUTHENTICATED, data]);
  }, [authDispatch, data]);

  const onSubmit = ({ first_name, last_name, email, password }) => {
    axios.get('/api/csrf').then(token => {
      signup({ first_name, last_name, email, password });
    });
  };

  const signupError = authError;
  const history = useHistory();
  return currentUser ? (
    <Redirect to="../UserProfile" />
  ) : (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOpenOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Volunteer Sign up
        </Typography>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          validateOnBlur={false}
        >
          {({ errors, handleSubmit, values }) => (
            <form onSubmit={handleSubmit} className={classes.form}>
              <TextField
                required
                label="First Name"
                name="first_name"
                autoComplete="first_name"
                autoFocus
              />
              <TextField
                required
                label="Last Name"
                name="last_name"
                autoComplete="last_name"
              />
              <TextField
                required
                label="Email Address"
                name="email"
                autoComplete="email"
              />
              <Typography variant="body1" color="error">{signupError}</Typography>
              <TextField
                required
                name="password"
                label="Password"
                type="password"
              />
              <TextField
                required
                name="confirm_password"
                label="Confirm Password"
                type="password"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Next
              </Button>
              <Grid container>
                <Grid item>
                  <Link
                    href=""
                    onClick={() => history.push('./')}
                    variant="body2"
                  >
                    Already have an account? Signin.
                  </Link>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}
export default SignupLong;
