import React from 'react';
import { Button, Box, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

const Pass = () => {
  const history = useHistory();
  const gotoProfile = () => {
    history.push('userprofile');
  };
  return (
    <>
      <Typography>
        Congratulations! You have successfully passed our CFMF Volunteer Program
        Quiz. We will send you an email with next steps to choosing your crew
        and officially enrolling in our program.
      </Typography>
      <Typography>{'\n'}</Typography>
      <Typography>We look forward to having you on our team!</Typography>
      <Box m={2} pt={3}>
        <Button
          fullWidth
          type="button"
          variant="contained"
          color="primary"
          onClick={gotoProfile}
        >
          Go to Profile
        </Button>
      </Box>
    </>
  );
};

export default Pass;
