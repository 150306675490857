import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import DescriptionRoundedIcon from '@material-ui/icons/DescriptionRounded';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import saveAs from 'file-saver';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  root: {},
  paper: {
    padding: theme.spacing(4),
    margin: 'auto',
    width: 320,
    minHeight: 75,
  },
}));

const Document = ({ data }) => {
  const classes = useStyles();
  const saveFile = () => {
    saveAs(`pdfs/${data.filename}`, data.filename);
  };
  return (
    <Grid item className={classes.root}>
      <Paper className={classes.paper}>
        <Grid container item spacing={2}>
          <Grid item xs={12} sm container>
            <Grid item xs container direction="column" spacing={2}>
              <Grid item xs>
                <Typography gutterBottom variant="subtitle1">
                  {data.title}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {data.description}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2" style={{ cursor: 'pointer' }}>
                  <Button color="primary" onClick={saveFile}>
                    Download
                  </Button>
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Avatar>
                <DescriptionRoundedIcon />
              </Avatar>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default Document;
