import { 
    ADD_USER, 
    FETCH_USER_REQUEST,
    FETCH_USER_SUCCESS,
    FETCH_USER_FAILURE,
    UPDATE_USER,
    UPDATE_USER_DATA_REQUEST,
    UPDATE_USER_DATA_SUCCESS,
    UPDATE_USER_DATA_FAILURE,
    UPDATE_USER_AGE} from './userActionTypes'

const INITIAL_STATE = {
    loading: false,
    errorMessage:'',
    user: {}
  }

  const userReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ADD_USER: return {
            ...state,
            user: action.payload
        }
        case FETCH_USER_REQUEST: return {
            ...state,
            loading: true
        }
        case FETCH_USER_SUCCESS: return {
            ...state,
            loading: false,
            errorMessage: '',
            user: action.payload
        }
        case FETCH_USER_FAILURE: return {
            ...state,
            loading: false,
            user: '',
            errorMessage: action.payload
        }
        case UPDATE_USER: return {
            ...state,
            user: {
                ...state.user,
                [action.payload.field]: action.payload.value
            }
        }
        case UPDATE_USER_AGE: return {
            ...state,
            user: {
                ...state.user,
                date_of_birth: action.payload.date_of_birth,
                age: action.payload.age,
                date_of_birth_text: action.payload.date_of_birth_text
            }
        }
        case UPDATE_USER_DATA_REQUEST: return {
            ...state,
            loading: true
        }

        case UPDATE_USER_DATA_SUCCESS: return {
            ...state,
            loading: false,
            errorMessage: '',
            update_status: 'Update successful.'
        }

        case UPDATE_USER_DATA_FAILURE: return {
            ...state,
            loading: false,
            errorMessage: action.payload.error
        }

        // const field = "province"
        // user[field] = "AB"
        // const user = { province: "AB" }
        // const user2 = { [field]: "AB" }
        //Acccessor
        default:
            return state
    }

}

export default userReducer