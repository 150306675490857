import React, { useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import EventVolunteer from './EventVolunteer';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { useQb } from '../../QB/useQb';
import OpportunitiesList from './OpportunitiesList';
import { EVRidContext, UpdatedEVContext } from './SelectCrewContext';

const useStyles = makeStyles(theme => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
}));

function EventVolunteerList({ userData }) {
  const { evrid, setEvrid } = useContext(EVRidContext);
  const { updatedEV } = useContext(UpdatedEVContext);
  // Event Volunteer
  const [
    {
      data: eventVolunteer,
      loading: eventVolunteerLoading,
      error: eventVolunteerError,
    },
    requestEventVolunteer,
  ] = useQb(
    { dbid: 'QB_TBL_EVENT_VOLUNTEERS', api: 'API_DoQuery' },
    { manual: true }
  );
  // Event Crew
  const [
    {
      data: transferOppsData,
      loading: transferOppsLoading,
      error: transferOppsError,
    },
    requestTransferOpps,
  ] = useQb(
    { dbid: 'QB_TBL_EVENT_CREWS', api: 'API_DoQuery' },
    { manual: true }
  );

  useEffect(() => {
    const qbUser = userData.body.qdbapi.record;
    const ageFilter = `AND{'70'.LTE.'${qbUser.age}'}`;
    const volStillReqFilter = "AND{'26'.GT.'0'}";

    async function fetchEV() {
      if (updatedEV) {
        requestEventVolunteer({
          query: `{'96'.EX.'1'}AND{'3'.EX.'${updatedEV}'}`,
          clist: '3.22.6.7.32.31.23.36.37.103',
        });
      } else {
        requestEventVolunteer({
          //Event Volunteer: Event is current = 1 and related volunteer = user rid
          query: `{'96'.EX.'1'}AND{'7'.EX.'${qbUser.record_id_}'}`,
          clist: '3.22.6.7.32.31.23.36.37.97.103',
        });
      }
    }
    async function fetchAvailableTransferOpps() {
      requestTransferOpps({
        query: `{'73'.EX.'1'}${ageFilter}${volStillReqFilter}`,
        clist: '3.50.10.11.12.13.14.15.26.70.71.72',
      });
    }
    fetchEV();
    fetchAvailableTransferOpps();
  }, [userData, updatedEV]);

  const resEventVolunteer = eventVolunteer && eventVolunteer.body.qdbapi.record;
  const resTransferOpps =
    transferOppsData && transferOppsData.body.qdbapi.record;
  const showReqTransfer = resTransferOpps;

  // Make sure the event volunteers list is an array
  const eventVolunteerList =
    resEventVolunteer === undefined
      ? []
      : resEventVolunteer && Array.isArray(resEventVolunteer)
      ? resEventVolunteer
      : [resEventVolunteer];

  const classes = useStyles();
  return (
    <>
      {eventVolunteerLoading && (
        <>
          <Typography
            variant="h6"
            align="center"
            color="textSecondary"
            paragraph
          >
            Loading ...
          </Typography>
        </>
      )}
      {eventVolunteerError && (
        <>
          <Typography
            variant="h6"
            align="center"
            color="textSecondary"
            paragraph
          >
            {eventVolunteerError}
          </Typography>
        </>
      )}
      {eventVolunteerList.length === 0 && (
        <Typography variant="h6" align="center" color="textSecondary" paragraph>
          No opportunities currently available.
        </Typography>
      )}
      {eventVolunteerList &&
        (!evrid ? (
          <>
            <div className={classes.heroContent}>
              <Container maxWidth="sm">
                <Typography
                  variant="h6"
                  align="center"
                  color="textSecondary"
                  paragraph
                >
                  Your current opportunities.
                </Typography>
              </Container>
            </div>
            <Container className={classes.cardGrid} maxWidth="md">
              <Grid container spacing={4}>
                {eventVolunteerList.map(eventVolunteer => (
                  <Grid item xs={12} key={eventVolunteer.record_id_}>
                    <EventVolunteer
                      key={eventVolunteer.record_id_}
                      rid={eventVolunteer.record_id_}
                      comment={eventVolunteer.volunteer_comments}
                      confirmation_status={eventVolunteer.confirmation_status}
                      event={eventVolunteer.event_label}
                      eventCrewDeprecated={
                        eventVolunteer.event_crew___crew___deprecated
                      }
                      eventCrew={eventVolunteer.event_crew___crew}
                      // event={eventVolunteer.event_crew___my_event}
                      transferToCrew={eventVolunteer.transfer_to_crew}
                      transferToCrewName={eventVolunteer.transfer_to_crew_name}
                      onRequestTransfer={() =>
                        setEvrid(eventVolunteer.record_id_)
                      }
                      showReqTransfer={showReqTransfer}
                    />
                  </Grid>
                ))}
              </Grid>
            </Container>
          </>
        ) : (
          <>
            <OpportunitiesList
              userData={userData}
              eventVolunteerRid={evrid}
              isCrewTransfer={true}
              updateEV={requestEventVolunteer}
            />
          </>
        ))}
    </>
  );
}

export default EventVolunteerList;
