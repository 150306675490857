import React, { useState, useEffect } from 'react';
import { useAlerts } from '../Common/useAlerts';
import {
  MenuItem,
  Button,
  Checkbox as MuiCheckbox,
  Container,
  CssBaseline,
  Grid,
  Link,
  List,
  ListItem,
  ListItemText,
  Select as MuiSelect,
  TextField as MuiTextField,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';
import useAxios from 'axios-hooks';
import { Base64 } from 'js-base64';
import { useStyles } from '../Common/useStyles';
import Contract from './Contract';
import DobPicker from '../Common/DobPicker';
import NavBar from '../Common/NavBar/NavBar';
import { TextField, Checkbox, FileUpload } from '../Common/form/formComponents';

import { useAuth } from '../../QB/useAuth';
import { User } from '../../QB/User';
import { useQb, fetchBy } from '../../QB/useQb';
import isValid from 'date-fns/isValid';

// Encode file to base64 for QB
const fileToBase64 = file => {
  const reader = new FileReader();

  return new Promise(resolve => {
    reader.onloadend = function() {
      // Since it contains the Data URI, we should remove the prefix and keep only Base64 string
      var b64 = reader.result.replace(/^data:.+;base64,/, '');
      resolve(b64);
    };
    reader.readAsDataURL(file);
  });
};

// Validation Schema
const validationSchema = ({ orientations }) =>
  Yup.object().shape({
    first_name: Yup.string().required('First name is required.'),
    last_name: Yup.string().required('Last name is required'),
    home_phone: Yup.string().when('cell_phone', {
      is: val => !val,
      then: Yup.string().required(
        'Please enter a valid home or cell phone number.'
      ),
      otherwise: Yup.string(),
    }),
    cell_phone: Yup.string(),
    date_of_birth: Yup.string().required('Date of Birth is required.'),
    tshirt: Yup.string().required('T-Shirt Size is required.'),
    emergency_contact: Yup.string().required('Emergency contact is required.'),
    emergency_contact_phone_number: Yup.string().required(
      'Emergency contact phone is required.'
    ),
    related_orientation_session: Yup.string().when(
      ['orientation_attended', 'passed_orientation_quiz'],
      {
        is: (orientation_attended, passed_orientation_quiz) =>
          orientation_attended === '0' && passed_orientation_quiz === '0',
        then: Yup.string().required('Please select an orientation session.'),
      }
    ),
    contract_accepted: Yup.string()
      .required('You must read and agree to the Code of Conduct to proceed.')
      .oneOf(
        ['1'],
        'You must read and agree to the Code of Conduct to proceed.'
      ),
    privacy: Yup.string().oneOf(
      ['1'],
      'You must consent to the use of your information to proceed.'
    ),
  });

// Map QB field names and field ids. Field names have to match what's in QB otherwise error!
const updateFids = {
  first_name: '9',
  last_name: '10',
  pronouns: '210',
  pronouns_other: '212',
  home_phone: '19',
  cell_phone: '12',
  city: '16',
  mailing_address: '28',
  province: '17',
  postal_code: '18',
  date_of_birth: '139',
  business_hours: '24',
  speak_another_language: '25',
  first_aid: '26',
  licensed_md_or_rn: '27',
  other: '32',
  other_description: '23',
  tshirt: '15',
  vegetarian: '21',
  employment: '29',
  reasons_to_volunteer: '31',
  medical_conditions_or_special_needs: '30',
  accommodations: '240',
  emergency_contact: '13',
  emergency_contact_phone_number: '14',
  license: '22',
  license_expire_date: '214',
  drivers_abstract: '215',
  massage: '216',
  massage_expire_date: '217',
  proserve_training: '68',
  proserve_expire_date: '86',
  proserve_certification_number: '218',
  safe_food_handling_certificate: '66',
  safe_food_cert_expire_date: '67',
  police_check: '69',
  police_check_date: '87',
  police_check_document: '219',
  contract_accepted: '204',
  privacy: '178',
  orientation_attended: '140',
  related_orientation_session: '56',
  file_driver_abstract: '229',
  file_police_check: '230',
  file_safe_food_handling: '239',
  passed_orientation_quiz: '236',
};

const fileUrl = url => `/api/file?name=${lastUrlSegment(url)}`;

const lastUrlSegment = url => {
  const segments = url.split('/');
  return segments[segments.length - 1];
};

// https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
const allowedUploadTypes = [
  'image/bmp',
  'text/csv',
  'application/msword',
  'application/pdf',
  'image/gif',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'text/html',
  'image/jpeg',
  'image/png',
  'application/rtf',
  'image/tiff',
  'text/plain',
  'image/webp',
];

const fileIsValidType = file =>
  file === null || allowedUploadTypes.includes(file.type);

function UserProfile() {
  const classes = useStyles();
  const { addAlert } = useAlerts();
  const [{ currentUser }] = useAuth();
  // User = user model with fids, currentUser = email of current user, data is the returned user
  const [{ data, loading, error }, reloadUser] = useQb(
    fetchBy(User, 'email', currentUser)
  );
  // const [
  //   { loading: updateInProgress, error: updateError },
  //   updateUser
  // ] = useQb(User, "API_EditRecord");

  const formUser = data && data.body.qdbapi.record;
  // console.log(`formUser: ${JSON.stringify(formUser)}`);
  // const driverAbstractDownloadUrl = window.URL.createObjectURL(Base64.decode(formUser.file_driver_abstract));
  // const policeCheckDownloadUrl = window.URL.createObjectURL(Base64.decode(formUser.file_police_check));

  const [driverAbstract, setDriverAbstract] = useState(null);
  const [policeCheckDocument, setPoliceCheckDocument] = useState(null);
  const [safeFoodDocument, setSafeFoodDocument] = useState(null);
  const driverAbstractIsValid = fileIsValidType(driverAbstract);
  const policeCheckDocumentIsValid = fileIsValidType(policeCheckDocument);
  const safeFoodDocumentIsValid = fileIsValidType(safeFoodDocument);

  const [
    { loading: updateInProgress, error: updateError },
    updateProfile,
  ] = useAxios({ url: '/api/updateProfile', method: 'POST' }, { manual: true });

  // Load orientation sessions
  const [
    { loading: loadingOrientations, data: orientations, error: orientationErr },
    requestOrientations,
  ] = useQb(
    { dbid: 'QB_TBL_ORIENTATION_SESSIONS', api: 'API_DoQuery' },
    { manual: true }
  );

  // Fetch orientation sessions
  useEffect(() => {
    const today = new Date();
    const dateNumber = today.getTime();
    requestOrientations({
      query: `{'7'.OAF.${dateNumber}}AND{'14'.GT.'0'}`,
      clist: '3.6.7.8.14',
    });
  }, []);

  const resOrientations = orientations && orientations.body.qdbapi.record;

  // Make sure the orientations are in an array
  const orientationsList =
    resOrientations === undefined
      ? []
      : resOrientations && Array.isArray(resOrientations)
      ? resOrientations
      : [resOrientations];

  // Submit
  const onSubmit = async values => {
    if (!isValid) {
      addAlert({
        severity: 'error',
        content: 'errors',
      });
      return;
    }

    if (
      !driverAbstractIsValid ||
      !policeCheckDocumentIsValid ||
      !safeFoodDocumentIsValid
    ) {
      addAlert({
        severity: 'error',
        content:
          'You have selected invalid files, please change your selections',
      });
      return;
    }
    const formData = new FormData();

    Object.entries(updateFids).forEach(([fieldName, fid]) =>
      formData.append(fid, values[fieldName])
    );
    formData.append('recordId', formUser.record_id_);

    // console.log(`UserProfilePlain.js: ${formData}`);

    if (driverAbstract) {
      // formData.append(updateFids.drivers_abstract, driverAbstract);
      formData.append('driverAbstractFilename', driverAbstract.name);
      formData.append(
        'driverAbstractBase64',
        await fileToBase64(driverAbstract)
      );
    }
    if (policeCheckDocument) {
      // formData.append(updateFids.police_check_document, policeCheckDocument);
      console.log(
        'police check document:',
        await fileToBase64(policeCheckDocument)
      );
      formData.append('policeCheckFilename', policeCheckDocument.name);
      formData.append(
        'policeCheckBase64',
        await fileToBase64(policeCheckDocument)
      );
    }
    if (safeFoodDocument) {
      formData.append('safeFoodHandlingFilename', safeFoodDocument.name);
      formData.append(
        'safeFoodHandlingBase64',
        await fileToBase64(safeFoodDocument)
      );
    }

    updateProfile({ data: formData })
      .then(() => {
        setDriverAbstract(null);
        setPoliceCheckDocument(null);
        setSafeFoodDocument(null);
        reloadUser();
        addAlert({
          severity: 'success',
          content: 'Profile updated!',
        });
      })
      .catch(e => {
        const errMsg = JSON.stringify(e.response.data);
        addAlert({
          severity: 'error',
          content: `${e}: ${errMsg}`,
        });
      });
  }; // End Submit

  return (
    <>
      <NavBar />
      <Container component="main" maxWidth="sm">
        <CssBaseline />

        {loading && <h2>Loading</h2>}
        {error && <h2>Error while loading user.</h2>}
        {formUser && (
          <>
            <Formik
              initialValues={formUser}
              onSubmit={onSubmit}
              validationSchema={validationSchema({ resOrientations })}
            >
              {({
                values,
                errors,
                isValid,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                validateForm,
              }) =>
                console.log('form errors:', errors) || (
                  <form onSubmit={handleSubmit}>
                    <div>
                      <img src="transparentpx.png" />
                    </div>
                    <Grid container justify="space-between" alignItems="center">
                      <Grid item>
                        <img src="cfmf_logo.svg" height="62" />
                      </Grid>
                      <Grid item>
                        <Typography variant="h6">
                          {formUser.portal_account___email_address}
                        </Typography>
                      </Grid>
                    </Grid>
                    <div>
                      <img src="transparentpx.png" />
                    </div>
                    <TextField
                      required
                      label="First Name"
                      name="first_name"
                      autoFocus
                    />
                    <TextField required label="Last Name" name="last_name" />
                    <TextField select name="pronouns" label="Pronouns">
                      <MenuItem value="He/ Him">He/ Him</MenuItem>
                      <MenuItem value="She/ Her">She/ Her</MenuItem>
                      <MenuItem value="They/ Them">They/ Them</MenuItem>
                      <MenuItem value="Other">Other</MenuItem>
                    </TextField>
                    {values.pronouns === 'Other' && (
                      <TextField
                        name="pronouns_other"
                        placeholder="Pronouns Other"
                      />
                    )}
                    <Typography variant="body2">
                      Pronouns will be displayed on volunteer badges. This is an
                      optional field.
                    </Typography>
                    {false && 'anything' /* <-- will be blank */}
                    <TextField name="mailing_address" label="Address" />
                    <TextField name="city" label="City" />
                    <TextField select name="province" label="Province">
                      <MenuItem value="AB">AB</MenuItem>
                      <MenuItem value="BC">BC</MenuItem>
                      <MenuItem value="MB">MB</MenuItem>
                      <MenuItem value="NB">NB</MenuItem>
                      <MenuItem value="NL">NL</MenuItem>
                      <MenuItem value="NT">NT</MenuItem>
                      <MenuItem value="NS">NS</MenuItem>
                      <MenuItem value="NU">NU</MenuItem>
                      <MenuItem value="ON">ON</MenuItem>
                      <MenuItem value="PE">PE</MenuItem>
                      <MenuItem value="QC">QC</MenuItem>
                      <MenuItem value="SK">SK</MenuItem>
                      <MenuItem value="YT">YT</MenuItem>
                    </TextField>
                    <TextField name="postal_code" label="Postal Code" />
                    <TextField name="home_phone" label="Home Phone" />
                    <TextField name="cell_phone" label="Cell Phone" />
                    <TextField
                      name="emergency_contact"
                      label="Emergency Contact"
                    />
                    <TextField
                      name="emergency_contact_phone_number"
                      label="Emergency Contact Phone"
                    />
                    <TextField name="employment" label="Employer" />
                    <Typography variant="body2" gutterBottom>
                      Birth dates are mandatory in order to properly filter
                      volunteers between 13+, 18+ and 25+ crews
                    </Typography>
                    <TextField
                      required
                      as={DobPicker}
                      name="date_of_birth"
                      openTo="year"
                      format="YYYY-MM-DD"
                      label="Date of Birth"
                      views={['year', 'month', 'date']}
                    />
                    <br />
                    <TextField required select name="tshirt" label="Shirt Size">
                      <MenuItem value="S">S</MenuItem>
                      <MenuItem value="M">M</MenuItem>
                      <MenuItem value="L">L</MenuItem>
                      <MenuItem value="XL">XL</MenuItem>
                      <MenuItem value="XXL">XXL</MenuItem>
                      <MenuItem value="XXXL">XXXL</MenuItem>
                      <MenuItem value="4XL">4XL</MenuItem>
                    </TextField>
                    <br />
                    <TextField
                      select
                      name="vegetarian"
                      label="Are you a vegetarian?"
                    >
                      <MenuItem value="Yes">Yes</MenuItem>
                      <MenuItem value="No">No</MenuItem>
                    </TextField>
                    <br />
                    <Typography variant="h5" gutterBottom>
                      Skills and Certifications
                    </Typography>
                    <p>
                      Please describe any special skills you have that may
                      assist with your placement
                    </p>
                    <Checkbox name="license" label="Drivers License" />
                    {values.license === '1' && (
                      <>
                        <TextField
                          as={DobPicker}
                          name="license_expire_date"
                          openTo="year"
                          format="YYYY-MM-DD"
                          label="License Expiration Date"
                          views={['year', 'month', 'date']}
                        />
                        <FileUpload
                          value={driverAbstract}
                          onChange={setDriverAbstract}
                          id="abstract"
                          label="Upload Driver's Abstract"
                          error={
                            driverAbstractIsValid
                              ? ''
                              : 'Invalid file type selected'
                          }
                        />
                        {/* {formUser.file_driver_abstract && (
                          <Link href={driverAbstractDownloadUrl}>
                            'Driver Abstract'
                          </Link>
                        )} */}
                      </>
                    )}
                    <br />
                    <Checkbox name="massage" label="Massage Certification" />
                    {values.massage === '1' && (
                      <>
                        <TextField
                          as={DobPicker}
                          name="massage_expire_date"
                          openTo="year"
                          format="YYYY-MM-DD"
                          label="Massage Certification Expiration Date"
                          views={['year', 'month', 'date']}
                        />
                      </>
                    )}

                    <br />
                    <Checkbox name="police_check" label="Police Check" />
                    {values.police_check === '1' && (
                      <>
                        <TextField
                          as={DobPicker}
                          name="police_check_date"
                          openTo="year"
                          format="YYYY-MM-DD"
                          label="Police Check Date"
                          views={['year', 'month', 'date']}
                        />
                        <FileUpload
                          value={policeCheckDocument}
                          onChange={setPoliceCheckDocument}
                          id="police_check_document"
                          label="Upload Police Check"
                          error={
                            policeCheckDocumentIsValid
                              ? ''
                              : 'Invalid file type selected'
                          }
                        />
                        {formUser.police_check_document && (
                          <Link href={fileUrl(formUser.police_check_document)}>
                            {lastUrlSegment(formUser.police_check_document)}
                          </Link>
                        )}
                      </>
                    )}
                    <br />
                    <Checkbox
                      name="proserve_training"
                      label="Proserve Training"
                    />
                    {values.proserve_training === '1' && (
                      <>
                        <TextField
                          as={DobPicker}
                          name="proserve_expire_date"
                          openTo="year"
                          format="YYYY-MM-DD"
                          label="Proserve Expiration Date"
                          views={['year', 'month', 'date']}
                        />
                        <TextField
                          name="proserve_certification_number"
                          label="Proserve Certification Number"
                        />
                      </>
                    )}
                    <br />
                    <Checkbox
                      name="safe_food_handling_certificate"
                      label="Safe Food Handling Certificate"
                    />
                    {values.safe_food_handling_certificate === '1' && (
                      <>
                        <TextField
                          as={DobPicker}
                          name="safe_food_cert_expire_date"
                          openTo="year"
                          format="YYYY-MM-DD"
                          label="Safe Food Handling Expiration Date"
                          views={['year', 'month', 'date']}
                        />
                        <FileUpload
                          value={safeFoodDocument}
                          onChange={setSafeFoodDocument}
                          id="safe_food_handling_document"
                          label="Upload Safe Food Handling Certificate"
                          error={
                            safeFoodDocumentIsValid
                              ? ''
                              : 'Invalid file type selected'
                          }
                        />
                      </>
                    )}
                    <br />
                    <Checkbox name="first_aid" label="Current First Aid" />
                    <br />
                    <Checkbox
                      name="business_hours"
                      label="Available During Business Hours"
                    />
                    <br />
                    <Checkbox
                      name="licensed_md_or_rn"
                      label="Licensed MD or RN"
                    />
                    <br />
                    <Checkbox
                      name="speak_another_language"
                      label="Speak Another Language"
                    />
                    <br />
                    <Checkbox name="other" label="Other" />
                    {values.other === '1' && (
                      <TextField
                        name="other_description"
                        label="Other description"
                      />
                    )}
                    <br />
                    <h3>What are your reasons for volunteering?</h3>
                    <TextField
                      name="reasons_to_volunteer"
                      label="Reasons for Volunteering"
                      multiline
                      rowsMax="4"
                    />

                    <h3>
                      Do you have any medical conditions that we should be aware
                      of that would impact your wellbeing while you are
                      volunteering at the Festival?
                    </h3>
                    <Typography variant="body2">
                      This information is shared voluntarily. Any medical
                      information shared will be kept in confidence.
                    </Typography>
                    <TextField
                      name="medical_conditions_or_special_needs"
                      label="Medical Conditions and or Special Concerns"
                      multiline
                      rowsMax="4"
                    />
                    <h3>
                      Will you require any accommodation during your time
                      volunteering at the Calgary Folk Music Festival?
                    </h3>
                    <Typography variant="body2">
                      This information will be shared with your Crew Coordinator
                      to ensure the best possible experience. While we strive to
                      accommodate as many members of our community as possible,
                      in certain instances we may be unable to provide these
                      accommodations - but we will do our best.
                    </Typography>
                    <TextField
                      name="accommodations"
                      label="Accommodations"
                      multiline
                      rowsMax="4"
                    />
                    {formUser.orientation_attended === '0' && resOrientations && (
                      <>
                        <h3>Orientation Sessions</h3>
                        <TextField
                          required
                          select
                          name="related_orientation_session"
                          label="Orientation Session"
                        >
                          {orientationsList.map(session => (
                            <MenuItem
                              key={session.record_id_}
                              value={session.record_id_}
                            >
                              {session.title}
                            </MenuItem>
                          ))}
                        </TextField>
                      </>
                    )}
                    <Contract component="div" updateUser={formUser} />
                    <Checkbox
                      name="contract_accepted"
                      label="Code of Conduct Accepted"
                    />

                    <Checkbox
                      name="privacy"
                      alignTop={true}
                      label="I consent to the collection, use and disclosure of my personal information for reasonable purposes by the Folk Festival Society of Calgary, including commercial activities."
                    />
                    <br />
                    {!isValid && errors && (
                      <>
                        <Typography variant="body1" color="error">
                          Please fix the following errors before updating your
                          profile:
                        </Typography>
                        {List && (
                          <List>
                            {Object.keys(errors).map(key => (
                              <ListItem key={key}>
                                <ListItemText style={{ color: 'red' }}>
                                  * {errors[key]}
                                </ListItemText>
                              </ListItem>
                            ))}
                          </List>
                        )}
                      </>
                    )}
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      disabled={updateInProgress || !isValid}
                    >
                      {updateInProgress ? (
                        <CircularProgress size={24} />
                      ) : (
                        'Update Profile'
                      )}
                    </Button>
                  </form>
                )
              }
            </Formik>
          </>
        )}
      </Container>
    </>
  );
}

export default UserProfile;
