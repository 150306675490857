import React from 'react';
import { useLocation } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import NavMenu from './NavMenu';
import { navStyles } from './navStyles';
import { useAuth, actions as authActions } from '../../../QB/useAuth';

export default function ButtonAppBar() {
  const [, authDispatch] = useAuth();
  const classes = navStyles();
  let location = useLocation();
  let page = '';

  switch (location.pathname) {
    case '/userprofile':
      page = 'Profile';
      break;
    case '/opportunities':
      page = 'Opportunities';
      break;
    default:
      page = '';
  }

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <NavMenu />
          <Typography variant="h6" className={classes.title}>
            {page}
          </Typography>

          <Button
            color="inherit"
            onClick={() => authDispatch([authActions.NOT_AUTHENTICATED])}
          >
            Logout
          </Button>
        </Toolbar>
      </AppBar>
    </div>
  );
}
