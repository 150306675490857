import React from 'react';
import {
  Avatar,
  Button,
  CssBaseline,
  Grid,
  Box,
  Typography,
  Link as MuiLink,
} from '@material-ui/core';
import Copyright from '../Common/Copyright';
import { Formik } from 'formik';
import Paper from '@material-ui/core/Paper';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import { makeStyles } from '@material-ui/core/styles';
import { TextField } from '../Common/form/formComponents';
import * as Yup from 'yup';
import { useAlerts } from '../Common/useAlerts';
import { useHistory } from 'react-router-dom';
import { useSubscribe } from '../../QB/useSubscribe';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: 'url(folkfest_stage.png)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light'
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'bottom',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('First Name is required.'),
  lastName: Yup.string().required('Last Name is required.'),
  email: Yup.string()
    .email()
    .required('Email is required'),
});

const initialValues = {
  email: '',
};

export default function Subscribe() {
  const classes = useStyles();
  const [{ data, loading, error }, subscribe] = useSubscribe();
  const { addAlert } = useAlerts();
  const history = useHistory();

  const onSubmit = ({ firstName, lastName, email }) => {
    try {
      subscribe(firstName, lastName, email)
        .then(() => {
          history.push('SubscribeSuccess');
        })
        .catch(err => {
          addAlert({
            severity: 'error',
            content: err,
          });
        });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <MailOutlineIcon />
          </Avatar>
          <Typography component="h1" variant="body2">
            New volunteer signup is not currently available. Please subscribe to
            be notified when signups are open. Thank you for your interest.
          </Typography>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            validateOnBlur={false}
          >
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit} className={classes.form}>
                <TextField
                  required
                  label="First Name"
                  name="firstName"
                  autoComplete="firstName"
                  autoFocus
                />
                <TextField
                  required
                  label="Last Name"
                  name="lastName"
                  autoComplete="lastName"
                />
                <TextField
                  required
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Subscribe
                </Button>
              </form>
            )}
          </Formik>
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Grid>
    </Grid>
  );
}
