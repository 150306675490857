import React from 'react';
import {
  TextField as MuiTextField,
  FormControl,
  InputLabel,
  FormControlLabel,
  Button,
  Checkbox as MuiCheckbox,
  Select as MuiSelect,
  FormHelperText,
} from '@material-ui/core';
import { useField } from 'formik';

export const Field = ({ as: Component, ...props }) => {
  // https://jaredpalmer.com/formik/docs/api/useField#fieldinputpropsvalue
  // `field` includes things like:
  // name
  // onBlur
  // onChange
  // value
  // checked
  const [field, meta] = useField(props);
  return (
    <Component
      error={meta.error && meta.touched}
      helperText={meta.error && meta.touched && <span>{meta.error}</span>}
      {...field}
      {...props}
    />
  );
};

export const TextField = props => (
  <Field
    as={MuiTextField}
    id={props.name}
    variant="outlined"
    margin="normal"
    fullWidth
    {...props}
  />
);

export const Select = props => (
  <FormControl variant="outlined">
    <InputLabel htmlFor={props.name}>{props.label}</InputLabel>
    <Field as={MuiSelect} {...props} />
  </FormControl>
);

/*
<html>
  <label for="firstName">First Name</label>
  <input type="text" id="firstName" name="firstName"></input>
</html>
*/

export const Checkbox = ({ label, alignTop, ...checkboxProps }) => {
  const { name } = checkboxProps;
  // TODO use meta.error and meta.touched to display error messages for this
  // component
  const [field, meta] = useField(name);
  const handleChange = event => {
    field.onBlur(event);
    field.onChange({
      target: { name, value: event.target.checked ? '1' : '0' },
    });
  };
  return (
    <>
      <FormControlLabel
        style={{ alignItems: alignTop ? 'flex-start' : 'center' }}
        control={
          <MuiCheckbox
            style={alignTop ? { paddingTop: 0 } : {}}
            {...field}
            {...checkboxProps}
            onChange={handleChange}
            checked={field.value === '1'}
          />
        }
        label={label}
      />
      {meta.error && meta.touched && (
        <FormHelperText error={meta.error && meta.touched}>
          {meta.error}
        </FormHelperText>
      )}
    </>
  );
};

export const FileUpload = ({ value, onChange, label, id, error }) => (
  <>
    <InputLabel htmlFor={id}>
      <Button variant="contained" color="primary" component="span">
        {value ? `${label} (${value.name})` : label}
      </Button>
      {error && (
        <FormHelperText style={{ color: 'red' }}>{error}</FormHelperText>
      )}
      <input
        type="file"
        id={id}
        style={{ display: 'none' }}
        onChange={event => onChange(event.target.files[0])}
      />
    </InputLabel>
  </>
);