import React, { useEffect } from 'react';
import axios from 'axios';
import {
  Avatar,
  Button,
  CssBaseline,
  Grid,
  Box,
  Typography,
  Link as MuiLink,
} from '@material-ui/core';
import Copyright from '../Common/Copyright';
import { Formik } from 'formik';
import Paper from '@material-ui/core/Paper';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { makeStyles } from '@material-ui/core/styles';
import { TextField } from '../Common/form/formComponents';
import { Redirect, Link } from 'react-router-dom';
import * as Yup from 'yup';
import {
  useAuth,
  actions as authActions,
  useAuthRequest,
} from '../../QB/useAuth';
import { useOpenSignup } from '../../QB/useSubscribe';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: 'url(folkfest_stage.png)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light'
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'bottom',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const validationSchema = Yup.object().shape({
  email: Yup.string().required('Email is required'),
  password: Yup.string().required('Password is required'),
});

const initialValues = {
  email: '',
  password: '',
};

export default function SignInSide() {
  const classes = useStyles();

  const [{ currentUser, authError }, authDispatch] = useAuth();
  // TODO show loading indicator when auth request is in progress
  const [{ data, error }, authenticate] = useAuthRequest();

  useEffect(() => {
    data && data.status === 200
      ? authDispatch([authActions.AUTHENTICATED, data.current_user])
      : data && data.status === 401
      ? authDispatch([authActions.NOT_AUTHENTICATED, data.error])
      : authDispatch([authActions.NOT_AUTHENTICATED, data]);
  }, [authDispatch, data]);

  // Get the latest CFMF Event to determine if subscribe or signup should render
  const [
    { data: openSignupData, error: openSignupError },
    openSignup,
  ] = useOpenSignup();

  let showSignup = '/subscribe';

  useEffect(() => {
    let cancelled = false;
    axios.get('/api/csrf').then(() => {
      if (!cancelled) openSignup();
    });

    return () => {
      cancelled = true;
    };
  }, []);

  openSignupData &&
  openSignupData.status === 200 &&
  parseInt(openSignupData.numMatches) > 0
    ? (showSignup = '/signup')
    : (showSignup = '/subscribe');

  const onSubmit = ({ email, password }) => {
    axios.get('/api/csrf').then(() => {
      authenticate({ email, password });
    });
  };
  // const history = useHistory();
  const errorMessage = authError;

  return currentUser ? (
    <Redirect to="../UserProfile" />
  ) : (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            CFMF Volunteer Sign In
          </Typography>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            validateOnBlur={false}
          >
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit} className={classes.form}>
                <TextField
                  required
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                />
                <TextField
                  required
                  name="password"
                  label="Password"
                  type="password"
                  autoComplete="current-password"
                />
                <Grid container>
                  <Grid item xs>
                    {errorMessage}
                  </Grid>
                </Grid>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Sign In
                </Button>
                <Grid container>
                  <Grid item xs>
                    <Link to="./forgotpassword" variant="body2">
                      Forgot password?
                    </Link>
                  </Grid>
                  <Grid item>
                    <MuiLink component={Link} to={showSignup} variant="body2">
                      {"Don't have an account? Sign Up"}
                    </MuiLink>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Grid>
    </Grid>
  );
}
