import {
    TOGGLE_LICENSE, 
    TOGGLE_MASSAGE, 
    TOGGLE_POLICE_CHECK, 
    TOGGLE_PROSERVE, 
    TOGGLE_SAFE_FOOD} from './certActionTypes'

const INITIAL_STATE = {
    license: false,
    massage: false,
    police_check: false,
    proserve_training: false,
    safe_food_handling_certification: false
}

const certReducer = (state = INITIAL_STATE, action) => {
    switch(action.type){
        case TOGGLE_LICENSE: return{
            ...state,
            license: action.payload
        }
        case TOGGLE_MASSAGE: return{
            ...state,
            massage: action.payload
        }
        case TOGGLE_POLICE_CHECK: return{
            ...state,
            police_check: action.payload
        }
        case TOGGLE_PROSERVE: return{
            ...state,
            proserve_training: action.payload
        }
        case TOGGLE_SAFE_FOOD: return{
            ...state,
            safe_food_handling_certification: action.payload
        }
        default:
            return state
    }
}

export default certReducer