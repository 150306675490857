import React, { useEffect, useState, useContext } from 'react';
import NavBar from '../Common/NavBar/NavBar';
import OpportunityList from './OpportunitiesList';
import EventVolunteerList from './EventVolunteerList';
import CannotShowOpps from './CannotShowOpps';
import { useAuth } from '../../QB/useAuth';
import { useQb, fetchBy } from '../../QB/useQb';
import { User } from '../../QB/User';
import {
  SelectCrewContext,
  EVRidContext,
  UpdatedEVContext,
} from './SelectCrewContext';
import { CssBaseline } from '@material-ui/core';

function OpportunitiesContainer() {
  const [{ currentUser }] = useAuth();
  const [{ data: userData, loading: userLoading, error: userError }] = useQb(
    fetchBy(User, 'email', currentUser)
  );
  const me = userData ? userData.body.qdbapi.record : {};
  // Check to see if the user has permission to view opportunities
  const mayShowOpportunities =
    userData &&
    me.orientation_attended === '1' &&
    me.current_confirmation_status !== 'Discontinue Contact' &&
    me.privacy === '1' &&
    me.contract_accepted === '1';

  const hasCurrentEVRecord =
    userData && parseInt(me.count_current_event_volunteers) > 0;

  // QB Requests
  const [
    {
      loading: crewTransferLoading,
      error: crewTransferError,
      data: crewTransferData,
    },
    requestTransfer,
  ] = useQb(
    { dbid: 'QB_TBL_EVENT_VOLUNTEERS', api: 'API_EditRecord' },
    { manual: true }
  );

  const [
    { loading: addEVLoading, error: addEVError, data: addEVData },
    addEventVolunteer,
  ] = useQb(
    {
      dbid: 'QB_TBL_EVENT_VOLUNTEERS',
      api: 'API_AddRecord',
    },
    { manual: true }
  );

  // Form states
  const [evrid, setEvrid] = useState(null);
  const evridValue = { evrid, setEvrid };
  const [updatedEV, setUpdatedEV] = useState(null);
  const updatedEVValue = { updatedEV, setUpdatedEV };
  const [showEV, setShowEV] = useState(false);

  const handleSelectNewCrew = evCrewId => {
    const reqAddEventVolunteer = {
      field: [
        { $: { fid: '6' }, _: 'Pending' },
        { $: { fid: '7' }, _: me.record_id_ },
        { $: { fid: '22' }, _: evCrewId },
      ],
    };

    addEventVolunteer(reqAddEventVolunteer)
      .then(response => {
        response.data.statusCode === 200 &&
        response.data.body.qdbapi.errcode == 0
          ? setShowEV(true)
          : alert('Something went wrong when trying to select this crew.');
      })
      .catch(error => {
        console.log('Add Event Volunteer failed', error);
      });
  };

  const handleTransferToCrew = (evCrewId, eventVolunteerRid) => {
    const reqTransferCrew = {
      rid: eventVolunteerRid,
      field: [
        { $: { fid: '6' }, _: 'Transfer Requested' },
        { $: { fid: '23' }, _: evCrewId },
      ],
    };

    requestTransfer(reqTransferCrew)
      .then(response => {
        const success =
          response.data.statusCode === 200 &&
          response.data.body.qdbapi.errcode == 0;
        if (success) {
          setUpdatedEV(response.data.body.qdbapi.rid);
          setEvrid(null);
          // console.log(
          //   'OpportunitiesContainer handleTransferToCrew updatedEV',
          //   response.data.body.qdbapi.rid
          // );
          window.location.reload();
          return response.data.body.qdbapi.rid;
        }
      })
      .catch(error => {
        console.log('Select Transfer Crew error: ', error);
      });
  };

  return (
    <>
      <CssBaseline />
      <NavBar position="relative" />
      <main>
        {!userData || Object.keys(userData).length === 0 ? (
          <>Loading ...</>
        ) : userLoading || addEVLoading || crewTransferLoading ? (
          <>Loading ...</>
        ) : (mayShowOpportunities && hasCurrentEVRecord) || showEV ? (
          <>
            <SelectCrewContext.Provider value={handleTransferToCrew}>
              <EVRidContext.Provider value={evridValue}>
                <UpdatedEVContext.Provider value={updatedEVValue}>
                  <EventVolunteerList userData={userData} />
                </UpdatedEVContext.Provider>
              </EVRidContext.Provider>
            </SelectCrewContext.Provider>
          </>
        ) : mayShowOpportunities && !hasCurrentEVRecord ? (
          <>
            <SelectCrewContext.Provider value={handleSelectNewCrew}>
              <OpportunityList userData={userData} />
            </SelectCrewContext.Provider>
          </>
        ) : !mayShowOpportunities &&
          Object.keys(userData).length !== 0 &&
          userData ? (
          <>
            <CannotShowOpps />
          </>
        ) : (
          <></>
        )}
      </main>
    </>
  );
}

export default OpportunitiesContainer;
