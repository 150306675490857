import React from 'react';

// Simply provide a context container to put whatever is needed in.
export const SelectCrewContext = React.createContext();

// Accepts an event volunteer rid. If there is an event volunteer rid passed then EventVolunteer
// list shows a list of transfer options.
export const EVRidContext = React.createContext();

export const UpdatedEVContext = React.createContext();
