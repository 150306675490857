import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { navStyles } from './navStyles';
import { useHistory } from 'react-router-dom';

export default function NavMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = navStyles();
  let history = useHistory();

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        edge="start"
        className={classes.menuButton}
        color="inherit"
        aria-label="menu"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MenuIcon />
      </IconButton>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => history.push('./userprofile')}>
          Profile
        </MenuItem>
        <MenuItem onClick={() => history.push('./opportunities')}>
          Opportunities
        </MenuItem>
        <MenuItem onClick={() => history.push('./changeemail')}>
          Change Email
        </MenuItem>
        <MenuItem onClick={() => history.push('./changepassword')}>
          Change Password
        </MenuItem>
        <MenuItem onClick={() => history.push('./videos')}>
          Onboarding Videos
        </MenuItem>
        <MenuItem onClick={() => history.push('./documents')}>
          Documents
        </MenuItem>
      </Menu>
    </div>
  );
}
