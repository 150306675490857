import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import useAxios from 'axios-hooks';

import { useAuth, actions as authActions } from './useAuth';

const baseUrl = 'https://cfmf.quickbase.com/db/';
const usertoken = ''; // Don't use user tokens on client side

// Manual determines whether the request gets automatically run when the
// component first loads. `manual = true` would mean DON'T run the request
// automatically.
// useQb(someReqConfiguration, { manual: false })
// useQb(someReqConfiguration)
export const useQb = ({ dbid, api, body }, { manual = false } = {}) => {
  const addBodyToQBPayload = body => ({
    url: baseUrl,
    //usertoken,
    response_fmt: 'json',
    dbid,
    api,
    body: {
      qdbapi: {
        usertoken,
        ...body,
      },
    },
  });

  const [state, runRequest] = useAxios(
    {
      url: '/api/qb',
      method: 'POST',
      data: addBodyToQBPayload(body),
    },
    { manual, useCache: false }
  );
  const history = useHistory();

  const [, authDispatch] = useAuth();

  useEffect(() => {
    const errorStatus = state.error && state.error.response.status;
    // If any API request comes back as not being authenticated, then tell the
    // authentication system and redirect back to the login page.
    if (errorStatus === 403 || errorStatus === 401) {
      authDispatch([authActions.NOT_AUTHENTICATED]);
      history.push('/');
    }
  }, [authDispatch, history, state.error]);

  return [
    state,
    (newBody = body) => runRequest({ data: addBodyToQBPayload(newBody) }),
  ];
};

const clist = model => Object.values(model.fids).join('.');

export const fetchBy = (table, field, value) => ({
  dbid: table.dbid,
  api: 'API_DoQuery',
  body: {
    query: `{'${table.fids[field]}'.EX.'${value}'}`,
    includeRids: '0',
    clist: clist(table),
  },
});

export const updateUser = (record_id_, field) => ({
  dbid: 'QB_TBL_VOLUNTEER',
  api: 'API_EditRecord',
  response_fmt: 'json',
  body: {
    record_id_,
    field,
  },
});

// This function will return the quickbase request configuration, which is an
// object that needs to be passed to useQB to perform the request.
export const update = (model, record) => ({
  dbid: model.dbid,
  api: 'API_EditRecord',
  // response_fmt: "json",
  body: {
    rid: record.record_id_,
    field: Object.entries(model).reduce(
      (output, [fieldName, value]) => [
        ...output,
        { field: { $: { fid: value }, _: record[fieldName] } },
      ],
      []
    ),
  },
});

export const useAddEventVoluneerRequest = () => {
  const [state, runRequest] = useAxios(
    {
      url: '/api/qb',
      method: 'POST',
    },
    { manual: true }
  );

  const addEventVolunteer = chosenCrew => {
    console.log('useAddEventVolunteer:', chosenCrew);
    const dbid = 'QB_TBL_PORTAL_ACCOUNTS';

    runRequest({
      data: {
        url: baseUrl,
        dbid,
        api: 'API_AddRecord',
        body: {
          field: [
            { $: { fid: '22' }, _: chosenCrew.related_event_crew },
            { $: { fid: '6' }, _: chosenCrew.confirmation_status },
            { $: { fid: '7' }, _: chosenCrew.related_volunteer },
          ],
        },
      },
    });
  };
  return [state, addEventVolunteer];
};

export const useFileUpload = () => {
  const [state, runRequest] = useAxios(
    {
      url: '/api/uploadfile',
      method: 'POST',
    },
    { manual: true }
  );
};
