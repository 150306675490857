import React from 'react';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

function Copyright() {
  return (
    <>
      <Typography variant="body2" color="textSecondary" align="center">
        {'Need help? '}
        <Link href="mailto:vsupport@calgaryfolkfest.com?Subject=Portal%20help">
          Contact Us
        </Link>
      </Typography>

      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="https://www.calgaryfolkfest.com//">
          Calgary Folk Music Festival
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    </>
  );
}

export default Copyright;
