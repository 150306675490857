import React, { useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Chip,
  Collapse,
  IconButton,
  Typography,
} from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ReactMarkdown from 'react-markdown';
import { SelectCrewContext } from './SelectCrewContext';

const useStyles = makeStyles(theme => ({
  root: {
    //maxWidth: 345,
    maxWidth: 300,
    flexGrow: 1,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

export default function OpportunityExp(props) {
  const classes = useStyles();
  const [data] = useState(props);
  const [expanded, setExpanded] = useState(false);
  const handleCrewSelect = useContext(SelectCrewContext);

  const handleCrewTransReq = async () => {
    await handleCrewSelect(data.eventCrewId, props.eventVolunteerRid);

    const res = await props.updateEV({
      query: `{'96'.EX.'1'}AND{'3'.EX.'${props.eventVolunteerRid}'}`,
      clist: '3.22.6.7.32.31.23.36',
    });
  };

  const handleSelectBtnClick = () => {
    // Check to see if its a crew transfer request
    props.isCrewTransfer
      ? handleCrewTransReq()
      : handleCrewSelect(data.eventCrewId, props.eventVolunteerRid);
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card className={classes.card}>
      <CardHeader
        avatar={
          <Avatar aria-label="recipe" className={classes.avatar}>
            CF
          </Avatar>
        }
        title={data.crewName}
      />

      <CardContent></CardContent>
      <CardActions disableSpacing>
        <Button
          className={classes.heroButtons}
          onClick={handleSelectBtnClick}
          key={data.eventCrewId}
          color="primary"
          variant="contained"
        >
          Select Crew
        </Button>
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </IconButton>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          {data.certPoliceCheck > 0 ? (
            <Chip size="small" label="Police Check" color="primary" />
          ) : (
            ''
          )}
          {data.certBusiness > 0 ? (
            <Chip size="small" label="Business Hours" color="primary" />
          ) : (
            ''
          )}
          {data.certDriversLicense > 0 ? (
            <Chip size="small" label="Drivers License" color="primary" />
          ) : (
            ''
          )}
          {data.certSafeFood > 0 ? (
            <Box m={1}>
              <Chip
                size="small"
                label="Safe Food Handling Certification"
                color="primary"
              />
            </Box>
          ) : (
            ''
          )}
          {data.certProserve > 0 ? (
            <Box m={1}>
              <Chip
                size="small"
                label="Proserve Certification"
                color="primary"
              />
            </Box>
          ) : (
            ''
          )}
          {data.certAge > 0 ? (
            <Box m={1}>
              <Chip
                size="small"
                label={'Min age ' + data.certAge}
                color="primary"
              />
            </Box>
          ) : (
            ''
          )}
          {data.certMassage > 0 ? (
            <Box m={1}>
              <Chip
                size="small"
                label="Massage Certification"
                color="primary"
              />
            </Box>
          ) : (
            ''
          )}
          <Typography variant="body2" color="textSecondary" component="div">
            <ReactMarkdown source={data.crewDescriptionFmt} />
          </Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
}
