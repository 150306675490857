import React, { useEffect, useContext, useReducer } from 'react';
import useAxios from 'axios-hooks';

const AuthStateContext = React.createContext();
const AuthDispatchContext = React.createContext();
const qbUserToken = ''; // Don't use usertokens on client side
const qbUrl = 'https://cfmf.quickbase.com/db/';

export const actions = {
  NOT_AUTHENTICATED: 'NOT_AUTHENTICATED',
  AUTHENTICATED: 'AUTHENTICATED',
};

const authReducer = (state, [type, payload]) => {
  // console.log('DISPATCHED', state, [type, payload]);
  switch (type) {
    case actions.NOT_AUTHENTICATED:
      return { ...state, currentUser: null, authError: payload };
    case actions.AUTHENTICATED:
      return { ...state, currentUser: payload, authError: undefined };
    default:
      throw new Error('Unrecognized authReducer action:', { type, payload });
  }
};

export const useAuth = () => {
  const state = useContext(AuthStateContext);
  const dispatch = useContext(AuthDispatchContext);
  return [state, dispatch];
};

const initialState = {
  currentUser: null,
};

export const Auth = ({ initialAuthUser, children }) => {
  const [state, dispatch] = useReducer(authReducer, undefined, () => ({
    currentUser:
      initialAuthUser || JSON.parse(localStorage.getItem('currentUser')),
  }));

  // // Restore the user (if it exists) when <Auth> is first rendered.
  // // Note: *must* come before the useEffect hook that synchronizes the stored
  // // and in-memory states, or else the stored state will be reset back to null
  // // before this hook can restore it.
  // useEffect(() => {
  //   try {
  //     const user = JSON.parse(localStorage.getItem("currentUser"));
  //     console.log("USER", user);
  //     dispatch([actions.AUTHENTICATED, user]);
  //   } catch (e) {
  //     // Ignore any errors
  //   }
  // }, [dispatch]);

  // Keep the stored user synchronized with the in-memory user.
  useEffect(() => {
    try {
      // console.log('SET USER', state.currentUser);
      localStorage.setItem('currentUser', JSON.stringify(state.currentUser));
    } catch (e) {
      // Ignore any errors
    }
  }, [state.currentUser]);

  return (
    <AuthStateContext.Provider value={state}>
      <AuthDispatchContext.Provider value={dispatch}>
        {children}
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
};

export const useChangeEmailRequest = () => {
  const [state, runRequest] = useAxios(
    {
      url: '/api/changeemail',
      method: 'POST',
    },
    { manual: true }
  );

  const changeEmail = ({ email, newEmail, relatedPortalAccount }) => {
    // console.log('useAuth changeEmail:', email, newEmail, relatedPortalAccount);
    const dbid = 'QB_TBL_PORTAL_ACCOUNTS';

    return runRequest({
      data: {
        url: qbUrl,
        dbid,
        email,
        newEmail,
        portalId: relatedPortalAccount,
      },
    });
  };
  return [state, changeEmail];
};

// Sends pw reset email
export const useRequestPwReset = () => {
  const [state, runRequest] = useAxios(
    {
      url: '/api/requestpwreset',
      method: 'POST',
    },
    { manual: true }
  );

  const requestPwReset = email => {
    // console.log('requestPwReset:', email);
    runRequest({
      data: { email },
    });
  };
  return [state, requestPwReset];
};

export const useChangePassword = () => {
  const [state, runRequest] = useAxios(
    {
      url: '/api/changepassword',
      method: 'POST',
    },
    { manual: true }
  );
  const changePassword = (password, newPassword, userid) => {
    return runRequest({
      data: {
        password,
        newPassword,
        userid,
      },
    });
  };
  return [state, changePassword];
};

export const useResetPassword = () => {
  const [state, runRequest] = useAxios(
    {
      url: '/api/resetpassword',
      method: 'POST',
    },
    { manual: true }
  );

  const resetPassword = (userid, token, password) => {
    // console.log('resetPassword:', userid, token, password);
    runRequest({
      data: {
        userid,
        token,
        password,
      },
    });
  };
  return [state, resetPassword];
};

export const useSignupRequest = () => {
  const [state, runRequest] = useAxios(
    {
      url: '/api/signup',
      method: 'POST',
    },
    { manual: true }
  );

  const signup = ({ first_name, last_name, email, password }) => {
    const fids = {
      email: 6,
      passwordHash: 7,
    };
    runRequest({
      data: {
        url: qbUrl,
        first_name,
        last_name,
        email,
        emailField: 'email_address',
        password,
        passwordField: 'password_hash',
        body: {
          usertoken: qbUserToken,
          clist: '6',
          query: `{'${fids.email}'.EX.'${email}'}`,
          includeRids: 1,
        },
      },
    });
  };
  return [state, signup];
};

export const useAuthRequest = () => {
  const [state, runRequest] = useAxios(
    {
      url: '/api/login',
      method: 'POST',
    },
    { manual: true }
  );

  const authenticate = ({ email, password }) => {
    const dbid = 'QB_TBL_PORTAL_ACCOUNTS';
    const fids = {
      email: 6,
      passwordHash: 7,
    };

    const request = {
      data: {
        url: qbUrl,
        dbid,
        emailField: 'email_address',
        password,
        passwordField: 'password_hash',
        body: {
          usertoken: qbUserToken,
          clist: Object.values(fids).join('.'),
          query: `{'${fids.email}'.EX.'${email}'}`,
          includeRids: 1,
        },
      },
    };

    // console.log('useAuth authenticate request: ', request);

    runRequest(request);
  };
  return [state, authenticate];
};
