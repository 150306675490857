import React from 'react';
import questions from './quiz.json';
import { useAlerts } from '../Common/useAlerts';
import { useAuth } from '../../QB/useAuth';
import useAxios from 'axios-hooks';
import { User } from '../../QB/User';
import { useQb, fetchBy } from '../../QB/useQb';
import { Formik } from 'formik';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';

const toArray = object => {
  let ar = [];
  for (let key in object) {
    object.hasOwnProperty(key) && ar.push(object[key]);
  }
  return ar;
};

const Questions = quizSetter => {
  const [{ currentUser }] = useAuth();
  const [{ data, loading, error }] = useQb(fetchBy(User, 'email', currentUser));
  const formUser = !loading && !error && data && data.body.qdbapi.record;
  const formData = new FormData();
  const [
    { loading: updateInProgress, error: updateError },
    updateProfile,
  ] = useAxios({ url: '/api/updateProfile', method: 'POST' }, { manual: true });

  // Intitialize quiz values
  const initialValues = {};
  questions.map(question => {
    initialValues[question.name] = '';
  });

  // Answer Key
  const answerKey = questions.map(ans => ans.answer);

  // Passing score = allow 1 wrong answer
  const pass = questions.length - 2;

  const onSubmit = (values, actions) => {
    const answers = toArray(values);

    const score = (ans, ansKey) => {
      let total = 0;
      ans.forEach((item, index) => {
        item === ansKey[index] && (total += 1);
      });
      return total;
    };

    if (score(answers, answerKey) > pass) {
      //Update Passed Orientation Quiz using volunteer rid
      formData.append('236', '1');
      formData.append('recordId', formUser.record_id_);

      updateProfile({ data: formData })
        .then(() => {
          quizSetter.quizSetter('pass');
          !updateInProgress &&
            !updateError &&
            addAlert({
              severity: 'success',
              content: 'You passed!',
            });
        })
        .catch(e => {
          const errMsg = JSON.stringify(e.response.data);
          addAlert({
            severity: 'error',
            content: `${e}: ${errMsg}`,
          });
        });
    } else {
      addAlert({
        severity: 'error',
        content: 'Sorry your score was not satisfactory.',
      });
      actions.resetForm();
      quizSetter.quizSetter('fail');
    }
    console.log(`Score: ${score(answers, answerKey)}`);
  };

  const { addAlert } = useAlerts();
  return (
    <>
      <Typography variant="h5" gutterBottom={true}>
        CFMF Volunteer Program Quiz
      </Typography>
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({ values, handleChange, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            {questions.map(q => {
              return (
                <React.Fragment key={q.key}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <FormLabel id={`${q.key}-label`} key={`fl-key-${q.key}`}>
                        {q.question}
                      </FormLabel>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl
                        id={`${q.key}-control`}
                        key={`fc-key-${q.key}`}
                      >
                        <RadioGroup
                          key={`radio-key-${q.key}`}
                          aria-label={q.name}
                          name={q.name}
                          value={values.choice}
                          onChange={handleChange}
                        >
                          {q.choices.map(opt => {
                            return (
                              <FormControlLabel
                                key={opt.key}
                                value={opt.value}
                                control={<Radio />}
                                label={opt.label}
                              />
                            );
                          })}
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  </Grid>
                </React.Fragment>
              );
            })}
            <Grid item xs={12}>
              <Box m={2} pt={3}>
                <Button
                  fullWidth
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Submit
                </Button>
              </Box>
            </Grid>
          </form>
        )}
      </Formik>
    </>
  );
};

export default Questions;
