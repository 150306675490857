import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { useQb } from '../../QB/useQb';
import { MenuItem, Button, Grid, Typography } from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { red } from '@material-ui/core/colors';
import { TextField } from '../Common/form/formComponents';
import { useAlerts } from '../Common/useAlerts';

const useStyles = makeStyles(theme => ({
  root: {
    //maxWidth: 345,
    maxWidth: 300,
    flexGrow: 1,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  avatar: {
    backgroundColor: red[500],
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  button: {
    margin: 2,
  },
  update: {
    background: 'linear-gradient(45deg, #4caf50 30%, #4caf50 90%)',
    color: 'white',
  },
}));

const validationSchema = Yup.object().shape({
  confirmation_status: Yup.string().required('Confirmation status is required'),
});

export default function EventVolunteer(props) {
  const classes = useStyles();
  const { addAlert } = useAlerts();

  const [
    {
      loading: updateConfirmationLoading,
      error: updateConfirmationError,
      data: updateConfirmationData,
    },
    updateConfirmation,
  ] = useQb(
    { dbid: 'QB_TBL_EVENT_VOLUNTEERS', api: 'API_EditRecord' },
    { manual: true }
  );
  return (
    <Card className={classes.card}>
      <CardHeader
        avatar={
          <Avatar aria-label="recipe" className={classes.avatar}>
            CF
          </Avatar>
        }
        title={`${props.event} ${props.eventCrew}`}
      />

      <CardContent>
        <Formik initialValues={props} validationSchema={validationSchema}>
          {({ values, isSubmitting }) => (
            <form>
              <Grid container>
                {props.confirmation_status === 'Transfer Requested' ? (
                  <>
                    <Typography variant="subtitle2">
                      {props.confirmation_status} to {props.transferToCrewName}
                    </Typography>
                  </>
                ) : props.confirmation_status === 'Pending' ||
                  props.confirmation_status === 'Confirmed' ? (
                  <>
                    <Typography variant="subtitle1" paragraph>
                      {props.confirmation_status}
                    </Typography>
                  </>
                ) : (
                  <>
                    {parseInt(props.eventCrewDeprecated) === 1 && (
                      <Typography
                        variant="body1"
                        style={{ color: 'red' }}
                        paragraph
                      >
                        This crew has been discontinued for this festival,
                        please click 'Request Transfer' and choose a different
                        crew if you want to volunteer for this event.
                      </Typography>
                    )}

                    <TextField
                      select
                      name="confirmation_status"
                      label="Confirmation Status"
                    >
                      <MenuItem value="Unconfirmed">Unconfirmed</MenuItem>
                      {parseInt(props.eventCrewDeprecated) === 0 && (
                        <MenuItem value="Confirmed">Confirmed</MenuItem>
                      )}
                      <MenuItem value="Recontact">Recontact</MenuItem>
                      <MenuItem value="Discontinue Contact">
                        Discontinue Contact
                      </MenuItem>
                    </TextField>
                  </>
                )}
              </Grid>
              <Grid container>
                <TextField
                  name="comment"
                  label="Comment"
                  multiline={true}
                  rows={3}
                ></TextField>
              </Grid>

              <div className={classes.heroButtons}>
                <Grid container spacing={2} justify="space-between">
                  {props.confirmation_status !== 'Transfer Requested' &&
                    props.confirmation_status !== 'Pending' &&
                    props.confirmation_status !== 'Confirmed' &&
                    props.showReqTransfer && (
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={props.onRequestTransfer}
                        >
                          Request Transfer
                        </Button>
                      </Grid>
                    )}

                  {
                    <Grid item>
                      <Button
                        className={classes.update}
                        variant="contained"
                        disabled={isSubmitting}
                        onClick={() => {
                          const reqUpdateConfirmation = {
                            rid: props.rid,
                            field: [
                              {
                                $: { fid: '6' },
                                _: values.confirmation_status,
                              },
                              {
                                $: { fid: '37' },
                                _: values.comment,
                              },
                            ],
                          };
                          updateConfirmation(reqUpdateConfirmation)
                            .then(response => {
                              const qbRes = response.data.body.qdbapi;
                              !response.status === 200
                                ? addAlert({
                                    severity: 'error',
                                    content: `There was an error when submitting your confirmation status: ${response.status}: ${response.statusText}`,
                                  })
                                : qbRes.errcode === '0'
                                ? addAlert({
                                    severity: 'success',
                                    content:
                                      'Your confirmation status has been successfully updated.',
                                  })
                                : addAlert({
                                    severity: 'error',
                                    content: `There was an error when submitting your confirmation status: ${qbRes.errcode}: ${qbRes.errtext}`,
                                  });
                              setTimeout(() => window.location.reload(), 5000);
                            })
                            .catch(error => {
                              addAlert({
                                severity: 'error',
                                content: `Update confirmation failed: ${error}`,
                              });
                            });
                        }}
                      >
                        Update
                      </Button>
                    </Grid>
                  }
                </Grid>
              </div>
            </form>
          )}
        </Formik>
      </CardContent>
    </Card>
  );
}
