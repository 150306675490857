import React, { useEffect } from 'react';
import axios from 'axios';
import {
  Button,
  Container,
  CssBaseline,
  TextField as MuiTextField,
  Typography,
} from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { useStyles } from '../Common/useStyles';
import NavBar from '../Common/NavBar/NavBar';
import { TextField } from '../Common/form/formComponents';
import { useAuth } from '../../QB/useAuth';
import { User } from '../../QB/User';
import { useChangePassword } from '../../QB/useAuth';
import { useQb, fetchBy } from '../../QB/useQb';
import { useAlerts } from '../Common/useAlerts';

const validationSchema = Yup.object().shape({
  password: Yup.string().required('Old password is required'),
  newPassword: Yup.string()
    .required('New Password is required.')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character'
    ),
  confirmPassword: Yup.string()
    .required('You must confirm your password')
    .oneOf([Yup.ref('newPassword')], 'Passwords must match'),
});

function ChangePassword() {
  const classes = useStyles();
  const [{ currentUser }] = useAuth();
  const { addAlert } = useAlerts();
  const [{ data, loading, error }] = useQb(fetchBy(User, 'email', currentUser));
  const history = useHistory();

  const initialValues = {
    password: '',
    newPassword: '',
    confirmPassword: '',
  };
  const [
    {
      data: chgPasswordData,
      loading: chgPasswordLoading,
      error: chgPasswordError,
    },
    changePassword,
  ] = useChangePassword();
  const formUser = data && data.body.qdbapi.record;

  const onSubmit = ({ password, newPassword }) => {
    changePassword(password, newPassword, formUser.related_portal_account)
      .then(() => {
        addAlert({
          severity: 'success',
          content: 'Password successfully changed.',
        });
        history.push('userprofile');
      })
      .catch(err =>
        addAlert({ severity: 'error', content: `Password reset error: ${err}` })
      );
  };
  return (
    <>
      <NavBar />
      {loading && <h2>Loading</h2>}
      {error && <h2>Error while loading user.</h2>}
      {formUser && (
        <>
          <Container component="main" maxWidth="sm">
            <CssBaseline />
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              validationSchema={validationSchema}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <form onSubmit={handleSubmit}>
                  <TextField
                    required
                    name="password"
                    label="Old Password"
                    type="password"
                    autoComplete="current-password"
                  />
                  <TextField
                    required
                    name="newPassword"
                    label="New Password"
                    type="password"
                    autoComplete="password"
                  />
                  <TextField
                    required
                    name="confirmPassword"
                    label="Confirm Password"
                    type="password"
                    autoComplete="password"
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                  >
                    Change Password
                  </Button>
                </form>
              )}
            </Formik>
          </Container>
        </>
      )}
    </>
  );
}

export default ChangePassword;
