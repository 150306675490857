import useAxios from 'axios-hooks';

export const useOpenSignup = () => {
  const [state, runRequest] = useAxios(
    {
      url: '/api/opensignup',
      method: 'POST',
    },
    { manual: true }
  );

  const openSignup = () => {
    return runRequest();
  };
  return [state, openSignup];
};

export const useSubscribe = () => {
  const [state, runRequest] = useAxios(
    {
      url: '/api/subscribe',
      method: 'POST',
    },
    {
      manual: true,
    }
  );

  const subscribe = (firstName, lastName, email) => {
    return runRequest({
      data: { firstName, lastName, email },
    });
  };
  return [state, subscribe];
};
