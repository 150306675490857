import React from 'react';
import NavBar from '../Common/NavBar/NavBar';
import docs from './docs.json';
import Document from './Document';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { Box, Typography } from '@material-ui/core';

const Documents = () => {
  return (
    <>
      <NavBar />
      <Container>
        <Box m={2}>
          <Typography variant="h4" gutterBottom={true}>
            Documents
          </Typography>
        </Box>

        <Grid container spacing={1}>
          {docs.map(data => {
            return <Document data={data} key={data.key} />;
          })}
        </Grid>
      </Container>
    </>
  );
};

export default Documents;
