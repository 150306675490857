import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Container,
  CssBaseline,
  TextField as MuiTextField,
  Typography,
} from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useStyles } from '../Common/useStyles';
import NavBar from '../Common/NavBar/NavBar';
import { TextField } from '../Common/form/formComponents';
import {
  useAuth,
  actions as authActions,
  useChangeEmailRequest,
} from '../../QB/useAuth';
import { User } from '../../QB/User';
import { useQb, fetchBy } from '../../QB/useQb';
import { useAlerts } from '../Common/useAlerts';

const validationSchema = currentEmail =>
  Yup.object().shape({
    email: Yup.string()
      .email()
      .required('Old email is required.')
      .oneOf(
        [currentEmail],
        'The email you entered does not match the one in your profile.'
      ),
    newEmail: Yup.string()
      .email()
      .required('New Email is required.'),
    confirmEmail: Yup.string()
      .required('You must confirm your email')
      .oneOf([Yup.ref('newEmail')], 'Emails must match'),
  });

//Create functionality to send verification emails. Use QB email notification. Create not verified flag.

function UpdateEmail() {
  const classes = useStyles();
  const [{ currentUser }, authDispatch] = useAuth();
  const { addAlert } = useAlerts();
  const [{ data, loading, error }] = useQb(fetchBy(User, 'email', currentUser));
  const history = useHistory();
  const [
    { data: chgEmailData, loading: chgEmailLoading, error: chgEmailError },
    changeEmail,
  ] = useChangeEmailRequest();
  const formUser = data && data.body.qdbapi.record;

  useEffect(() => {
    chgEmailData && chgEmailData.status === 200
      ? authDispatch([authActions.AUTHENTICATED, chgEmailData.current_user])
      : console.log(`Email change failed.`);
  }, [authDispatch, chgEmailData]);

  const onSubmit = (values, actions) => {
    const email = values.email;
    const newEmail = values.newEmail;
    const relatedPortalAccount = values.related_portal_account;

    // Update the existing email in the portal table
    // Todo: check that the email is not already in use or that it is the same as the current one.
    changeEmail({ email, newEmail, relatedPortalAccount })
      .then(() => {
        addAlert({
          severity: 'success',
          content: 'Your email was successfully changed.',
        });
        history.push('userprofile');
      })
      .catch(e => {
        addAlert({
          severity: 'error',
          content: `Something went wrong when trying to update your email: ${e}`,
        });
      });
  };
  return (
    <>
      <NavBar />
      {loading && <h2>Loading</h2>}
      {error && <h2>Error while loading user.</h2>}
      {formUser && (
        <>
          <Container component="main" maxWidth="sm">
            <CssBaseline />
            <Formik
              initialValues={formUser}
              onSubmit={onSubmit}
              validationSchema={validationSchema(currentUser)}
            >
              {({
                values,
                errors,
                status,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <form onSubmit={handleSubmit}>
                  <TextField
                    required
                    type="email"
                    name="email"
                    label="Old Email"
                  />
                  <Typography color="secondary">
                    {status && status.msg && <div>{status.msg}</div>}
                  </Typography>
                  <TextField
                    required
                    type="email"
                    name="newEmail"
                    label="New Email"
                  />
                  <TextField
                    required
                    type="email"
                    name="confirmEmail"
                    label="Confirm Email"
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                  >
                    Change Email
                  </Button>
                </form>
              )}
            </Formik>
          </Container>
        </>
      )}
    </>
  );
}

export default UpdateEmail;
