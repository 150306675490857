import React from "react";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment-timezone";

//https://material-ui-pickers.dev/demo/datepicker

// Create a copy of `moment`, but where using it as a function actually
// delegates to `moment.tz` with UTC set as the timezone. This makes it possible
// to get the date picker to work with UTC values, but without having to
// globally force everything in the app to use UTC.
const momentUTC = (...args) => moment.tz(...args, "UTC");
Object.assign(momentUTC, moment);

/**
 * The expected value is a string of the millisecond date as of midnight, UTC
 * for that calendar date (eg. Jan 1 1970 would be 0, whereas Jan 1 1970 in
 * MST is actually 25200000)
 */
function DobPicker(props) {
  // Formik expects the onChange to be given a React synthetic event, but the
  // MuiPickers send a bare date value instead, so the value has to be converted
  // to a mock event before onChange can be called.
  const handleChange = momentDate => {
    const posixAsString = String(momentDate.valueOf());
    props.onChange({ target: { name: props.name, value: posixAsString } });
  };

  return (
    <MuiPickersUtilsProvider utils={MomentUtils} libInstance={momentUTC}>
      <DatePicker
        {...props}
        value={Number(props.value)}
        onChange={handleChange}
      />
    </MuiPickersUtilsProvider>
  );
}

export default DobPicker;
