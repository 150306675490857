import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ReactMarkdown from 'react-markdown';

import { Field } from '../Common/form/formComponents';

const useStyles = makeStyles(theme => ({
  root: {
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
}));

const Contract = ({ updateUser, handleAccept }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');

  const handleClickOpen = scrollType => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleAcceptClick = () => {
    updateUser.contract_accepted = '1';
    handleAccept(updateUser);
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);

  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <>
      <div>
        <Typography className={classes.root} component={'span'}>
          <Link href="#" onClick={handleClickOpen('paper')}>
            Review Code of Conduct
          </Link>
        </Typography>
        {/* <Button onClick={handleClickOpen('paper')}>Read Contract</Button> */}
        <Dialog
          open={open}
          onClose={handleClose}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle id="scroll-dialog-title">Code of Conduct</DialogTitle>
          <DialogContent dividers={scroll === 'paper'}>
            <Typography variant="body2" color="textSecondary" component="span">
              <ReactMarkdown source="## Statement of Philosophy / Code of Conduct" />
              <ReactMarkdown source="The Festival wishes to maintain a work environment that fosters personal and for some, professional growth, for all volunteers while adhering to the organization’s values. Maintaining such an environment is the responsibility of every volunteer. Because of their front-facing role, Calgary Folk Music Festival volunteers have the additional responsibility to lead in a manner which fosters an environment of respect for each person." />
              <ReactMarkdown source="It is the responsibility of all volunteers to:" />
              <ReactMarkdown
                source="* Foster cooperation and communication among each other;
* Treat each other in a fair manner, with dignity and respect;
* Promote harmony and teamwork in all relationships;
* Take responsibility for their own actions;
* Strive for mutual understanding of standards for performance expectations, and communicate routinely to reinforce that understanding;
* Encourage and consider opinions of other teammates; 
* Encourage collaboration;
* Address conflict in the volunteer environment by responding fairly and quickly;
* Administer all policies equitably and fairly, recognizing that jobs are different but each is important; that individual performance should be recognized and measured against predetermined standards;
* Recognize that volunteers in their personal lives may experience crisis and show compassion and understanding;
* Represent the organization in a responsible, fair and respectful manner; and
* Demonstrate the organization’s values."
              />
              <ReactMarkdown source="## Equity Diversity Inclusion Policy" />
              <ReactMarkdown source="The Folk Festival Society of Calgary (FFSC) strives to be a diverse, inclusive, and equitable workplace where all employees, contractors, vendors, volunteers, and patrons, whatever their gender, race, ethnicity, national origin, age, sexual orientation or identity, religion, education or disability, feel valued and respected. We are committed to a nondiscriminatory approach that provides equal opportunity for participation and advancement in our volunteer program. We respect and value diverse life experiences and heritages and ensure that all voices are valued and heard." />
              <ReactMarkdown source="We’re committed to modelling diversity and inclusion for the entire arts industry of the nonprofit sector, and to maintaining an inclusive environment with equitable treatment for all." />
              <ReactMarkdown source="**To provide informed, authentic leadership for cultural equity, the FFSC strives to:**" />
              <ReactMarkdown
                source="* See diversity, inclusion, and equity as connected to our mission and critical to ensure the well-being of our staff, volunteers, and the arts communities we serve;
* Explore potential underlying, unquestioned assumptions that interfere with inclusiveness;
* Evaluate and take action to acknowledge and dismantle inequities within our policies, systems, programs, and services on an ongoing basis and to keep our community informed and to seek ongoing feedback;
* Practice and encourage transparent communication in all interactions;
* Commit time and resources to expand more diverse leadership within our board, staff, committee, advisory bodies, and volunteer leadership roles;
* Commit time and resources to training for staff, board members, and volunteer leadership roles; and
* Lead with respect and tolerance. We expect all volunteers to embrace this notion and to express it in workplace interactions and through everyday practices."
              />
              <ReactMarkdown source="## Assumption of Risk and Liability Related to COVID-19" />
              <ReactMarkdown source="As a volunteer, I understand and acknowledge that COVID-19 has been declared a pandemic by the World Health Organization and is contagious. I commit to following all public health recommendations concerning the risks of COVID-19 and following all of the FFSC risk mitigation strategies and protocols. I also acknowledge that FFSC cannot guarantee that I will not be exposed to or infected with COVID-19 through my participation in the Calgary Folk Music Festival and associated events. I also acknowledge that my participating in the Calgary Folk Music Festival and associated events may increase my risk of contracting COVID-19. I understand and acknowledge that I assume all risk and liability related to COVID-19 exposure and infection." />
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Field
              as={AcceptButton}
              close={() => setOpen(false)}
              name="contract_accepted"
              color="primary"
            >
              Agree
            </Field>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

const AcceptButton = ({ onChange, close, children, name, ...props }) => {
  const onClick = () => {
    onChange({ target: { name, value: '1' } });
    close();
  };
  return (
    <Button onClick={onClick} {...props}>
      {children}
    </Button>
  );
};

export default Contract;
