import React, { useState, useContext } from 'react';
import { Snackbar } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { useEffect } from 'react';

// Obtained from https://stackoverflow.com/a/2117523
// Create a uuid to uniquely identify each alert.
const uuid = () =>
  'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });

const AddAlertContext = React.createContext();

export const useAlerts = () => {
  const addAlert = useContext(AddAlertContext);
  return { addAlert };
};

export const Alerts = ({ children }) => {
  const [alerts, setAlerts] = useState([]);
  const removeAlert = ({ id }) =>
    setAlerts(prev => prev.filter(item => item.id !== id));

  // Set up timeouts for removing alerts any time the list of alerts changes.
  // Clear the timeouts on cleanup to avoid trying to change the state after the
  // component has been destroyed.
  useEffect(() => {
    const timeoutIds = alerts.map(alert =>
      setTimeout(() => removeAlert(alert), alert.expiry - Date.now())
    );
    return () => timeoutIds.forEach(id => clearTimeout(id));
  }, [alerts]);

  // Returns a callback for removing the alert (will have no effect if the alert
  // has already timed out).
  //
  // `alertConfig` is an object containing the following:
  // `title` (optional) - a title for the alert
  // `severity` - one of the severity options for MaterialUI Alerts
  // `content` - the content of the alert (can be a string or JSX)
  const addAlert = (alertConfig, timeout = 5000) => {
    // `id` is needed so that the array of <Alert> elements can have meaningful
    // keys, and also provides a more reliable way to remove alerts than testing
    // object equality.
    const alert = { ...alertConfig, expiry: Date.now() + timeout, id: uuid() };
    // Alerts should be ordered with most recent first.
    setAlerts(prev => [alert, ...prev]);

    return () => removeAlert(alert);
  };

  return (
    <AddAlertContext.Provider value={addAlert}>
      <Snackbar open={alerts.length > 0}>
        <div>
          {alerts.map(alert => (
            <Alert
              key={alert.id}
              style={{ marginTop: 10 }}
              elevation={6}
              variant="filled"
              severity={alert.severity}
              onClose={() => removeAlert(alert)}
            >
              {alert.title && <AlertTitle>{alert.title}</AlertTitle>}
              {alert.content}
            </Alert>
          ))}
        </div>
      </Snackbar>
      {children}
    </AddAlertContext.Provider>
  );
};
