import React, { useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import {
  Avatar,
  Box,
  Button,
  Container,
  CssBaseline,
  Grid,
  Link as MuiLink,
  TextField as MuiTextField,
  Typography,
} from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Copyright from '../Common/Copyright';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useStyles } from '../Common/useStyles';
import { TextField } from '../Common/form/formComponents';
import { useRequestPwReset } from '../../QB/useAuth';
import { useAlerts } from '../Common/useAlerts';

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email()
    .required('Email is required.'),
});

function ForgotPassword() {
  const classes = useStyles();
  const { addAlert } = useAlerts();
  const initialValues = {
    email: '',
  };
  const [{ data, loading, error }, requestPwReset] = useRequestPwReset();

  useEffect(() => {
    data && data.status === 200
      ? addAlert({
          severity: 'success',
          content: `Password reset instructions have been sent to ${data.recipient}.`,
        })
      : data
      ? addAlert({
          severity: 'success',
          content: `Password reset instructions have been sent to ${data.recipient}.`,
        })
      : console.log('Waiting for request.');
  }, [data, error]);

  const onSubmit = ({ email }) => {
    axios.get('/api/csrf').then(token => {
      requestPwReset(email);
    });
  };

  return (
    <>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Forgot Password
          </Typography>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            validateOnBlur={false}
          >
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <TextField required name="email" label="email" type="email" />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Request Password Reset
                </Button>
                <Grid container>
                  <Grid item xs></Grid>
                  <Grid item>
                    <MuiLink component={Link} to="/" variant="body2">
                      {'Return to Sign In'}
                    </MuiLink>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
    </>
  );
}

export default ForgotPassword;
