import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import { BrowserRouter, Route } from 'react-router-dom';
import { createStore, applyMiddleware } from 'redux';
import logger from 'redux-logger';
import { Provider } from 'react-redux';
import rootReducer from './redux/rootReducer';
import thunk from 'redux-thunk';
import SignInSide from './components/Auth/SignInSide';
import SignupLong from './components/Auth/SignupLong';
import Subscribe from './components/Auth/Subscribe';
import SubscribeSuccess from './components/Auth/SubscribeSuccess';
import ForgotPassword from './components/Auth/ForgotPassword';
import UserProfile from './components/UserProfile/UserProfilePlain';
import OpportunitiesContainer from './components/Opportunity/OpportunitiesContainer';
import { Auth } from './QB/useAuth';
import PrivateRoute from './components/Auth/PrivateRoute';
import ChangePassword from './components/Auth/ChangePassword';
import ResetPassword from './components/Auth/ResetPassword';
import UpdateEmail from './components/Auth/ChangeEmail';
import Videos from './components/OrientationVideos/Videos';
import Documents from './components/Documents/Documents';
import Quiz from './components/Quiz/Quiz';
import { Alerts } from './components/Common/useAlerts';

require('dotenv').config();

const store = createStore(rootReducer, applyMiddleware(thunk, logger));

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <App>
        <Auth>
          <Alerts>
            <Route path="/" exact component={SignInSide} />
            <Route path="/signup" exact component={SignupLong} />
            <Route path="/subscribe" exact component={Subscribe} />
            <Route
              path="/subscribesuccess"
              exact
              component={SubscribeSuccess}
            />
            <Route path="/forgotpassword" exact component={ForgotPassword} />
            <Route
              path="/resetpassword/:userId/:token"
              render={({ match }) => (
                <ResetPassword
                  userId={match.params.userId}
                  token={match.params.token}
                />
              )}
            />
            <PrivateRoute path="/userprofile" exact component={UserProfile} />
            <PrivateRoute path="/videos" exact component={Videos} />
            <PrivateRoute path="/documents" exact component={Documents} />
            <PrivateRoute path="/quiz" exact component={Quiz} />
            <PrivateRoute
              path="/opportunities"
              exact
              component={OpportunitiesContainer}
            />
            <PrivateRoute path="/changeemail" exact component={UpdateEmail} />
            <PrivateRoute
              path="/changepassword"
              exact
              component={ChangePassword}
            />
          </Alerts>
        </Auth>
      </App>
    </Provider>
  </BrowserRouter>,
  document.getElementById('root')
);
