import React, { useState } from 'react';
import NavBar from '../Common/NavBar/NavBar';
import Pass from './Pass';
import Fail from './Fail';
import Questions from './Questions';
import { Container } from '@material-ui/core';

const Quiz = () => {
  // quiz, pass, fail
  const [quizState, setQuizState] = useState('quiz');

  function handleQuiz(value) {
    console.log(value);
    setQuizState(value);
  }

  return (
    <>
      <NavBar />
      <Container component="main" maxWidth="sm">
        {quizState === 'quiz' && <Questions quizSetter={handleQuiz} />}
        {quizState === 'pass' && <Pass />}
        {quizState === 'fail' && <Fail quizSetter={handleQuiz} />}
      </Container>
    </>
  );
};

export default Quiz;
