const dbid = 'QB_TBL_VOLUNTEER';
const fids = {
  record_id_: 3,
  first_name: 9,
  last_name: 10,
  pronoun: 210,
  pronoun_other: 212,
  home_phone: 19,
  cell_phone: 12,
  city: 16,
  mailing_address: 28,
  province: 17,
  postal_code: 18,
  date_of_birth: 139,
  business_hours: 24,
  speak_another_language: 25,
  first_aid: 26,
  licensed_md_or_rn: 27,
  other: 32,
  other_description: 23,
  tshirt: 15,
  vegetarian: 21,
  employment: 29,
  reasons_to_volunteer: 31,
  emergency_contact: 13,
  emergency_contact_phone_number: 14,
  age: 141,
  date_of_birth_text: 213,
  license: 22,
  license_expire_date: 214,
  drivers_abstract: 215,
  massage: 216,
  massage_expire_date: 217,
  proserve_training: 68,
  proserve_expire_date: 86,
  proserve_certification_number: 218,
  safe_food_handling_certificate: 66,
  safe_food_cert_expire_date: 67,
  police_check: 69,
  police_check_date: 87,
  police_check_document: 219,
  medical_conditions_or_special_needs: 30,
  accommodations: 240,
  contract_accepted: 204,
  privacy: 178,
  orientation_attended: 140,
  current_confirmation_status: 177,
  count_of_confirmed: 222,
  count_current_event_volunteers: 223,
  email: 147,
  related_portal_account: 146,
  related_orientation_session: 56,
  file_driver_abstract: 229,
  file_police_check: 230,
  file_safe_food_handling: 239,
  passed_orientation_quiz: 236,
  show_quiz: 237,
};

export const User = {
  dbid,
  fids,
};
